.carousel {

    .carousel-control-pause,
    .carousel-control-play {
        background-color: transparent;
        border: 0;
        border-radius: 100%;
        color: #fff;
        margin: -5px;
        padding: 5px;
        text-align: center;

        span {
            display: block;
        }

        &:hover {
            background-color: rgba(color('nijmegen-primary', 'lighten-1'), .5);
            cursor: pointer;
        }

        &:focus {
            background: rgba(color('nijmegen-primary', 'lighten-1'), .5);
            box-shadow: 0 0 2px #fff;
        }
    }

    .carousel-control-prev[role='button'],
    .carousel-control-next[role='button'] {
        background-color: color('nijmegen-grey', 'darken-3');
        border-radius: 100%;
        height: 56px;
        opacity: 1;
        top: calc(50% - 28px);
        width: 56px;
        z-index: 2;

        &.carousel-control-prev {
            left: 84px;
        }

        &.carousel-control-next {
            right: 84px;
        }

        &:hover {
            @extend .z-depth-1;
            background-color: color('nijmegen-primary', 'lighten-1');
        }

        &:focus {
            background: color('nijmegen-primary', 'lighten-1');
            box-shadow: 0 0 2px #fff;
            outline: none;
        }
    }

    .carousel-indicators {
        bottom: 10px;

        li {
            position: relative;
            background-color: transparent;
            border: 2px solid color('white', 'base');
            height: 6px;
            opacity: 1;
            width: 6px;

            &.carousel-play-pause {
                border: 0;
                color: color('white', 'base');
                display: block;
                height: 10px;
                margin-left: 30px;
                margin-top: -7px;
                min-height: 24px;
                min-width: initial;
                text-indent: 0;
            }

            &.active {
                background-color: color('white', 'base');
                border: 2px solid $focus-color;
            }

            &:not(.carousel-play-pause):focus {
                width: 8px;
                height: 8px;
                margin: -1px 2px;
            }
        }
    }

    .carousel-item img {
        align-self: center;
        width: 100%;
    }
}
