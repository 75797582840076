$mobile-and-down: 'only screen and (max-width: 767px)';
$tablet-only: 'only screen and (min-width: #{$medium-screen-portrait}) and (max-width : #{$medium-screen})';

.navbar {
    background-color: color('nijmegen-brand', 'base');

    @media #{$medium-and-down} {
        margin: 0;
        padding-bottom: 0;
    }

    &::before {
        box-shadow: 0 3px 3px rgba(0, 0, 0, .25);
        content: '';
        height: 50px;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: height .5s ease-in-out;
        width: 100%;

        @media #{$tablet-only}{
            height: 62px;
        }

        @media #{$large-and-up} {
            content: none;
        }

        @media #{$medium-and-down} {
            background-color: inherit;
        }
    }

    .container {
        @media #{$medium-and-down} {
            display: block;
            margin-left: 0;
            margin-right: 0;
            max-width: 100%;
        }
    }

    &-brand-container {
        @media #{$large-and-up} {
            overflow: hidden;
            transition: width .5s ease-in-out;
            width: 150px;
        }
    }

    &-brand {
        padding-bottom: 0;
        padding-top: 0;
        position: relative;
        transition: margin-right .5s ease-in-out;

        &:focus {
            @include tab-focus-minimal-inverted;
        }

        @media #{$medium-and-down} {
            display: inline-block;
            left: 50%;
            margin-right: 0;
            position: relative;
            transform: translateX(-50%);
        }

        img {
            @media #{$medium-and-down} {
                transition: none;
            }
        }

        @media only screen and (min-width: #{$large-screen}) {
            margin-right: 6.75rem;
        }

        .logo{
            height: 42px;
            left: 0;
            opacity: 0;
            position: absolute;
            transition: opacity .5s ease-in-out;
            width: 34px;
            z-index: 1;

            @media #{$mobile-and-down} {
                height: 35px;
            }
        }

        .logo-labeled {
            height: 50px;
            opacity: 1;
            overflow: hidden;
            position: relative;
            transition: height .5s ease-in-out, opacity .5s ease-in-out;
            z-index: 2;

            @media #{$medium-and-down} {
                height: 35px;
            }
        }
    }

    &-toggler {
        margin-left: -.75rem;
        position: relative;

        &:focus {
            @include tab-focus-minimal-inverted;
        }

        & + .navbar-brand {
            @media #{$medium-and-down} {
                margin-left: -42px;
            }
        }

        &-icon {
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgb(255, 255, 255)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 8h24M4 16h24M4 24h24"/%3E%3C/svg%3E');
        }
    }

    &-nav {
        a {
            font-size: to-rem(20);
        }
    }

    &__search {
        @media #{$medium-and-down} {
            left: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: margin .5s ease-in-out;
        }

        @media #{$tablet-only} {
            margin: 7px 0;
        }

        .autocomplete {
            @media #{$medium-and-down} {
                display: none;
            }

            &__form {
                position: relative;

                @media #{$medium-and-down} {
                    margin: 0;
                    padding: 0;
                }

                .waves-input-wrapper {
                    border-radius: 0;

                    @media #{$medium-and-down} {
                        width: 100%;
                    }
                }

                .mdi {
                    color: inherit;
                }
            }

            &__result-list {
                max-height: calc(100vh - 74px);
                overflow: auto;
                position: absolute;
                width: 100%;

                @media #{$medium-and-down} {
                    box-shadow: 0 3px 3px rgba(0, 0, 0, .25), 0 7px 7px rgba(0, 0, 0, .15);
                }
            }

            &__search-button, &__clear-button {
                background-color: transparent;
                border: 0;
                font-size: to-rem(24);
                line-height: 1;
                padding: 0;
                position: absolute;
                right: 0;
                top: calc(50% - 12px);

                @media #{$medium-and-down} {
                    background-color: transparent;
                    border: 0;
                    color: #fff;
                    display: block;
                    height: 25px;
                    padding: 0;
                    position: absolute;
                    right: .5rem;
                    width: 25px;
                }

                &:focus {
                    @media #{$medium-and-down} {
                        @include tab-focus-minimal-inverted;
                    }
                }
            }

            &__search-button {
                @media #{$medium-and-down} {
                    display: none;
                    right: 1rem;
                    top: calc(50% - 10px);
                }

                &:hover {
                    cursor: default;
                }

                &:focus {
                    @media #{$medium-and-down} {
                        border-bottom: 0;
                    }
                }
            }

            &__clear-button {
                @media #{$medium-and-down} {
                    position: absolute;
                    right: 4rem;
                }
            }

            &__search-close-button {
                @media #{$medium-and-down} {
                    display: none;
                    line-height: 20px;
                    padding-bottom: 5px;
                    right: 1rem;
                    text-align: right;
                    width: auto;
                }
            }

            &__input {
                background-color: transparent;
                border: 0;
                border-bottom: 2px solid color('nijmegen-grey', 'base');
                border-radius: 0;
                box-sizing: border-box;
                font-size: to-rem(20);
                height: 2rem;
                margin-left: 0;
                margin-right: 0;
                padding: 0 to-rem(30) 0 0;
                width: 100%;

                @media #{$medium-and-down} {
                    border-bottom: 0;
                    margin: .5rem 1rem;
                    padding-right: to-rem(75);
                    width: calc(100% - 2rem);
                }

                &:focus {
                    border-bottom: 2px solid $light-focus-color;
                    box-shadow: none;

                    &::placeholder {
                        color: color('white', 'base');
                    }

                    + .mdi {
                        color: color('white', 'base');
                    }
                }

                &::-ms-clear {
                    display: none;
                }
            }
        }
    }

    &__search-button, &__search-close-button {
        background-color: transparent;
        border: 0;
        color: #fff;
        display: none;
        height: 25px;
        padding: 0;
        position: absolute;
        right: .5rem;
        top: 14px;
        width: 25px;

        @media #{$medium-and-down} {
            display: block;
        }

        &:focus {
            @include tab-focus-minimal-inverted;
        }
    }

    &__search-close-button {
        display: none;
        line-height: 20px;
        padding-bottom: 5px;
        right: 1rem;
        text-align: right;
        width: auto;
    }

    &-collapse {
        margin-top: to-rem(6);

        @media #{$tablet-only}{
            margin-top: to-rem(12);
        }

        @media #{$large-and-up} {
            margin-top: 0;
        }

        @media #{$medium-and-down} {
            margin-left: -1rem;
            margin-right: -1rem;
        }
    }

    &-nav {
        .nav-item {
            @media #{$medium-and-down} {
                border-bottom: 1px solid color('nijmegen-brand', 'darken-3');
            }

            .nav-link {
                margin: 0;

                @media #{$medium-and-down} {
                    padding: .75em 1rem;
                }

                @media #{$large-and-up} {
                    margin: 0 to-rem(19);
                }
            }

            a {
                color: color('white', 'base');
                transition: padding .5s ease-in-out;

                &:hover {
                    border-bottom: 1px solid #fff;
                }

                &:focus {
                    @include tab-focus-minimal-inverted;
                }

                &::before {
                    @media #{$medium-and-down} {
                        @include mdi('chevron-right');
                        font-size: 20px;
                        opacity: 0;
                        transition: width .2s ease-in-out, opacity .2s ease-in-out;
                        width: 0;
                    }
                }

                &:hover,
                &:active {
                    @media #{$medium-and-down} {
                        border-bottom: 0;
                    }

                    &::before {
                        @media #{$medium-and-down} {
                            opacity: 1;
                            width: 20px;
                        }
                    }
                }
            }

            &.active {
                background-color: transparent;
                position: relative;

                &::after {
                    @media #{$large-and-up} {
                        background-color: color('nijmegen-brand', 'darken-3');
                        bottom: -12px;
                        content: '';
                        height: 8px;
                        left: 0;
                        position: absolute;
                        right: 0;
                        transition: bottom .5s ease-in-out, height .5s ease-in-out;
                    }
                }

                .nav-link {
                    color: color('nijmegen-brand', 'lighten-2');

                    &:focus,
                    &:hover {
                        color: color('nijmegen-brand', 'lighten-1');
                    }

                    &:focus {
                        @include tab-focus-minimal-inverted;
                    }
                }
            }
        }
    }

    &.top-nav-collapse {
        @media #{$large-and-up} {
            padding-bottom: 3px;
            padding-top: 3px;
        }

        &::before {
            @media #{$tablet-only}{
                height: 54px;
            }
        }

        .nav-link {
            @media #{$large-and-up} {
                padding-bottom: 0;
                padding-top: 0;
            }
        }

        .navbar-brand {
            @media #{$large-and-up} {
                margin-right: 2rem;
            }
        }

        .navbar-brand-container {
            @media #{$large-and-up} {
                width: 35px;
            }
        }

        .logo-labeled{
            @media #{$large-and-up} {
                height: 42px;
                opacity: 0;
            }
            @media #{$medium-and-down} {
                opacity: 0;
            }
        }

        .logo {
            @media #{$large-and-up} {
                opacity: 1;
            }
            @media #{$medium-and-down} {
                left: calc(50% - 17px);
                opacity: 1;
                position: absolute;
            }
        }

        .nav-item.active::after{
            @media #{$large-and-up} {
                background-color: color('nijmegen-brand', 'darken-3');
                bottom: -6px;
                content: '';
                height: 5px;
                left: 0;
                position: absolute;
                right: 0;
            }
        }

        .navbar__search{
            margin: 0;
        }
    }

    &.scrolling-navbar {
        @media #{$mobile-and-down} {
            padding-bottom: 5px;
            padding-top: 5px;
        }
    }

    &.search-open {
        .navbar__search-button {
            @media #{$medium-and-down} {
                display: none;
            }
        }

        .navbar__search-close-button {
            @media #{$medium-and-down} {
                display: block;
            }
        }

        .autocomplete {
            @media #{$medium-and-down} {
                display: block;
            }
        }
    }

    &.navbar-primary {
        .navbar__search {
            @media #{$medium-and-down} {
                background-color: color('nijmegen-brand', 'base');
            }

            .autocomplete {
                &__search-button, &__clear-button {
                    color: color('white', 'base');
                }
                &__input {
                    color: color('white', 'base');
                    &::placeholder {
                        color: color('white', 'base');
                    }
                }

                &__result-list {
                    @media #{$medium-and-down} {
                        background-color: color('nijmegen-brand', 'base');
                    }
                }

                &__result-link, &__section-header {
                    @media #{$medium-and-down} {
                        color: color('white', 'base');
                    }
                }
            }
        }
    }

    &.navbar-light {
        background-color: rgba(255, 255, 255, .97);

        @media #{$large-and-up} {
            background-color: rgba(255, 255, 255, .5);
        }

        &.top-nav-collapse {
            @media #{$large-and-up} {
                background-color: rgba(255, 255, 255, .97);
            }
        }

        .navbar-toggler-icon {
            background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgb(168, 10, 45)" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 8h24M4 16h24M4 24h24"/%3E%3C/svg%3E');
        }

        .navbar__search-button, .navbar__search-close-button {
            color: color('nijmegen-brand', 'base');
        }

        .navbar__search {
            @media #{$medium-and-down} {
                background-color: color('white', 'base');
            }
        }

        .navbar {
            &-brand {
                &:focus {
                    @include tab-focus-minimal;
                }
            }

            &-toggler {
                &:focus {
                    @include tab-focus-minimal;
                }
            }

            &__search {
                .autocomplete {
                    &__search-button,
                    &__clear-button {
                        &:focus {
                            @include tab-focus-minimal;
                        }
                    }
                    &__input:focus {
                        border-bottom: 2px solid $focus-color;
                    }
                }
            }

            &__search-button:focus {
                @include tab-focus-minimal;
            }

            &-nav {
                .nav-item {
                    .nav-link:focus {
                        @include tab-focus-minimal;
                    }

                    &.active {
                        .nav-link {
                            &:focus {
                                @include tab-focus-minimal;
                            }
                        }
                    }
                }
            }
        }

        .autocomplete {
            &__search-button, &__clear-button {
                color: color('nijmegen-brand', 'base');
            }

            &__input {
                color: $body-color;

                &::placeholder {
                    color: color('nijmegen-brand', 'base');
                }

                &:focus {
                    &::placeholder {
                        color: inherit;
                    }
                }
            }
        }

        .navbar-nav .nav-item .nav-link {
            border-bottom: 0;
            color: color('nijmegen-brand', 'base');

            @media #{$medium-and-down} {
                color: $body-color
            }

            &:hover {
                color: color('nijmegen-brand', 'darken-3');
                @media #{$medium-and-down} {
                    color: $body-color
                }
            }
        }
    }
}
