.card {
    & > figure {
        margin-bottom: 0;
    }

    figure {
        @include aspect-ratio(7, 3);
        overflow: hidden;

        &.compatibility-object-fit {
            background-position: center center;
            background-size: cover;

            img.content {
                opacity: 0;
            }
        }

        img.content {
            object-fit: cover;
        }

        .badge {
            bottom: 0;
            font-size: to-rem(12);
            left: 0;
            padding: to-rem(6) to-rem(20);
            position: absolute;
            z-index: 2;
        }
    }
}

.card-body {
    figure {
        margin-left: -1.25rem;
        margin-right: -1.25rem;
    }

    img {
        border-radius: 0;
    }

    .mdi {
        color: color('nijmegen-brand', 'base');
    }

    p:last-of-type {
        margin-top: 4rem;
    }
}

.card-title {
    color: color('nijmegen-brand', 'base');
    font-family: 'Oranda', sans-serif;
    font-weight: $font-weight-normal;
    word-wrap: break-word;
}

.card-read-more a {
    text-decoration: underline;
}

.card .card-body .card-text {
    color: $body-color;
    font-size: inherit;
}

.card-filename {
    .card-title,
    .card-body h3,
    .card-body h5 {
        font-size: to-rem(28);
        line-height: 1.5;
        margin-bottom: 1.5rem;
        min-height: 90px;
        padding: 0;
    }

    p:last-of-type {
        margin-top: 0;
    }
}

.card-news {
    display: flex;
    flex-direction: column-reverse;

    .card-title,
    .card-body h3,
    .card-body h5 {
        font-size: to-rem(24);
        line-height: 1.5;
        margin-bottom: 1rem;
        padding: 0;
    }
}

.card-services {
    .card-title,
    .card-body h3,
    .card-body h5 {
        font-size: to-rem(28);
        line-height: calc(32 / 28);
        margin-bottom: 2rem;
        padding: 0;
    }

    p:last-of-type {
        margin-top: 2rem;
    }
}
